import React from 'react';
import { TrPlaceholder, TrLoader } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { TablePagination } from '../../../components/tables';
import { usePolicyInvoices } from '../../../views/policyInvoices/hooks';
import { useActiveAccount, HideFromTypes } from '../../../shared/activeAccount';
import { BoxContainer, Render, RenderData, TrGraphQLErrors } from '../../../components';

import { InvoicesTableRow } from '../components';
import { InvoicesTableFilters } from '.';

export default function InvoicesTable() {
  const {
    roleIsAtLeast,
    hideFromTypes,
    TYPES: { CLIENT },
    ROLES: { POLICY_MANAGER }
  } = useActiveAccount();
  const isAtLeastPolicyManager = roleIsAtLeast(POLICY_MANAGER);
  const hideFromClients = hideFromTypes([CLIENT]);

  const { variables, invoices, loading, error, paginationProps, clearFilters, ...rest } = usePolicyInvoices({});

  const colSpan = isAtLeastPolicyManager && !hideFromClients ? 8 : 7;

  return (
    <>
      <InvoicesTableFilters variables={variables} clearFilters={clearFilters} {...rest} />
      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Number</th>
              <th>Date</th>
              <th>Due</th>
              <th>Service Date Range</th>
              <th>Client Total</th>
              <HideFromTypes types={[CLIENT]}>
                <Render if={isAtLeastPolicyManager}>
                  <th>Broker Total</th>
                </Render>
              </HideFromTypes>
              <th>Policy External Id</th>
              <th>Client Name</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={invoices}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No invoices</TrPlaceholder>}
            >
              {invoices =>
                invoices?.map(invoice => (
                  <InvoicesTableRow
                    key={invoice.id}
                    invoice={invoice}
                    showBrokerTotal={isAtLeastPolicyManager && !hideFromClients}
                  />
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
