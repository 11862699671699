import { CustomInput, Tooltip } from 'reactstrap';
import { Td, Tr, useToggle } from '@oforce/global-components';

export default function PolicyRow({ formik, policy, policies, synchronizedPolicyIds, onChange }) {
  const [showTooltip, toggleTooltip] = useToggle();

  return (
    <Tr
      id={`row-${policy?.id}`}
      style={formik?.values?.policyIds.includes(policies?.id) ? { backgroundColor: '#e6eaf2' } : {}}
    >
      {synchronizedPolicyIds?.includes(policy?.id) ? (
        <Tooltip target={`row-${policy?.id}`} placement="top" isOpen={showTooltip} toggle={toggleTooltip}>
          The product's invoice has already been synchronized to this policy.
        </Tooltip>
      ) : (
        null
      )}
      <Td>
        <CustomInput
          id={`checkbox-${policy?.id}`}
          type="checkbox"
          checked={formik?.values?.policyIds.includes(policy?.id) || synchronizedPolicyIds?.includes(policy?.id)}
          disabled={synchronizedPolicyIds?.includes(policy?.id)}
          onChange={() => onChange(policy)}
        />
      </Td>
      <Td>{policy?.name}</Td>
    </Tr>
  );
}
