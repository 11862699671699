import { CustomInput } from 'reactstrap';
import { BoxContainer, FormSection, TrGraphQLErrors } from '../../../components';
import { TablePagination } from '../../../components/tables';
import { usePolicies } from '../../policies/hooks';
import { useState } from 'react';
import { TrLoader, TrPlaceholder, RenderData, FieldError } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import PolicyRow from './PolicyRow';

export default function SelectPoliciesFormStep({ formik, product }) {
  const colSpan = 3;
  const [selectAll, setSelectAll] = useState(true);

  const { policies, loading, error, paginationProps } = usePolicies({
    initialFilters: { productId: product?.id, activeStatus: 'ACTIVE' }
  });
  const { invoiceTemplateDocId } = product;
  const synchronizedPolicyIds = policies
    ?.filter(policy => policy.invoiceTemplateDocId === invoiceTemplateDocId)
    .map(({ id }) => id);

  const unsynchronizedPolicyIds = policies
    ?.filter(policy => policy.invoiceTemplateDocId !== invoiceTemplateDocId)
    .map(({ id }) => id);

  const toggleSelectAll = selectAll => {
    !selectAll ? formik?.setFieldValue('policyIds', unsynchronizedPolicyIds) : formik?.setFieldValue('policyIds', []);
    setSelectAll(!selectAll);
  };

  const onPolicySelected = policy => {
    if (formik?.values?.policyIds?.includes(policy?.id)) {
      formik?.setFieldValue(
        'policyIds',
        formik?.values?.policyIds.filter(policyId => policyId !== policy?.id)
      );
    } else {
      formik?.setFieldValue('policyIds', [...formik?.values?.policyIds, policy?.id]);
    }
  };

  return (
    <FormSection title="Select Policies">
      <FieldError name="policyIds" />
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <CustomInput
                  id="toggleSelectAllPolicies"
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => toggleSelectAll(selectAll)}
                  className="mb-1"
                />
              </th>
              <th>Policy Name</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={policies}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No policies</TrPlaceholder>}
              style={{ border: '0px!important' }}
            >
              {policies =>
                policies?.map(policy => (
                  <PolicyRow
                    key={policy?.id}
                    formik={formik}
                    policy={policy}
                    policies={policies}
                    synchronizedPolicyIds={synchronizedPolicyIds}
                    onChange={onPolicySelected}
                  />
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </FormSection>
  );
}
