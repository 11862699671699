import { gql, useQuery } from '@apollo/client';

import { PRODUCT_INSURANCE_TYPE_FIELDS } from './useInsuranceType';

export const PRODUCT_CORE_FIELDS = gql`
  fragment ProductCoreFields on Product {
    id
    name
    externalId
    automateInvoiceGeneration
    invoiceGenerationDay
    enableCustomStartDates
    enableCustomTerminationDates
    prorateSetting
    invoicingSchedule
    prorateAddons
    hasApiFactoredField
    created
    updated
    pricingRateType
    startDateBackdatingLimit
    terminationDateBackdatingLimit
    invoiceDueDay
    billingRule
    snapshotCustomDay
    apiOnlyExposureUpdates
    onePremiumRequired
    customData
    backdate
    ratingZoneSource
    invoiceTemplateDocId
  }
`;

export const GET_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      ...ProductCoreFields
      activeExposureCount
      masterCertificate {
        startNumber
        endNumber
        suffix
        prefix
        nextNumber
      }
      companies {
        id
        companyId
        name
        role
      }
      invoiceTemplate {
        id
        filename
        url
      }
      ...ProductInsuranceTypeFields
    }
  }
  ${PRODUCT_CORE_FIELDS}
  ${PRODUCT_INSURANCE_TYPE_FIELDS}
`;

export const GET_PRODUCT_PRICING_OPTIONS_COUNT = gql`
  query GetProductPricingOptionsCount($policyId: ID!) {
    policy(id: $policyId) {
      id
      pricingOptionsCount
    }
  }
`;

export default function useProduct({ id }) {
  const { data, loading, error } = useQuery(GET_PRODUCT, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return { product: data?.product, loading, error };
}
