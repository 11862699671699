import { gql, useMutation } from '@apollo/client';
import { GET_POLICY_INVOICES } from '../hooks/usePolicyInvoices';
import { INTERVAL_TYPES } from './useGenerateInvoiceFormOptions';
import moment from 'moment';
import { transformDate } from '../../../utils/yupValidation';
import usePolicyInvoiceInfo from './usePolicyInvoiceInfo';

export const GENERATE_INVOICE = gql`
  mutation generate($input: GenerateInvoiceInput!) {
    generateInvoice(input: $input) {
      id
    }
  }
`;

export const getServiceDates = values => {
  const {
    WEEKLY: { SPECIFIC_WEEK, WEEK_RANGE },
    MONTHLY: { SPECIFIC_MONTH, MONTH_RANGE }
  } = INTERVAL_TYPES;

  const intervalType = values?.intervalType?.value;
  const hasDateRange = !!values?.dateRange;

  const startDate = {
    [SPECIFIC_MONTH]: moment(values?.specificMonth).startOf('month'),
    [MONTH_RANGE]: hasDateRange && moment(values?.dateRange[0]).startOf('month'),
    [SPECIFIC_WEEK]: hasDateRange && values?.dateRange[0],
    [WEEK_RANGE]: hasDateRange && values?.dateRange[0]
  }[intervalType];

  const endDate = {
    [SPECIFIC_MONTH]: moment(values?.specificMonth).endOf('month'),
    [MONTH_RANGE]: hasDateRange && moment(values?.dateRange[1]).endOf('month'),
    [SPECIFIC_WEEK]: hasDateRange && values?.dateRange[1],
    [WEEK_RANGE]: hasDateRange && values?.dateRange[1]
  }[intervalType];

  return { startDate, endDate };
};

export default function useGenerateInvoiceFormSubmit({ refetchInvoicesArgs, onCompleted, policyId, policy }) {
  const { canGenerateOffCycleInvoice } = usePolicyInvoiceInfo({ policy });
  const [generate, { loading, error }] = useMutation(GENERATE_INVOICE, {
    onCompleted: ({ generateInvoice }) => {
      if (generateInvoice.id) {
        onCompleted(generateInvoice);
      }
    },
    onError: () => {},
    refetchQueries: [
      {
        query: GET_POLICY_INVOICES,
        variables: { ...refetchInvoicesArgs }
      }
    ]
  });

  // if specific month
  //    start date is first of month
  //    end date is last of month

  // if month range
  //    start date is first day of the first month
  //    end date is last day of the last month

  // if specific week
  //    start date is first day of week (ex. Sunday)
  //    end date is last day of week (ex. Saturday)

  // if week range
  //    start date is first day of the first week (ex. Sunday)
  //    end date is last day of the last week (ex. Saturday)

  const formSubmit = values => {
    const { startDate, endDate } = getServiceDates(values);

    const exposureElectionFactorValues =
      values?.factorValues?.validatedRows?.length > 0 ? values?.factorValues?.validatedRows : null;

    generate({
      variables: {
        input: {
          offCycle: canGenerateOffCycleInvoice,
          startDate: transformDate(startDate) || null,
          endDate: transformDate(endDate) || null,
          invoiceDate: transformDate(values?.invoiceDate),
          ...(exposureElectionFactorValues && { exposureElectionFactorValues }),
          ...(values?.invoiceEmailAddresses && {
            invoiceEmailAddresses: values?.invoiceEmailAddresses?.map(option => option?.value)
          }),
          policyId
        }
      }
    });
  };

  return { formSubmit, loading, error };
}
