import moment from 'moment';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import { useToggle, TabBar, TabButton, Badge, Label } from '@oforce/global-components';
import { TabPage } from '../../../components/tabs';
import { formatCurrency, titleCase } from '../../../utils/formatting';
import {
  BoxContainer,
  FilterDropdown,
  FilterLabel,
  FiltersContainer,
  GraphQLErrors,
  Render,
  SkeletonLoader
} from '../../../components';
import { HideFromRoles, HideFromTypes, useActiveAccount } from '../../../shared/activeAccount';
import { PageTitle, PageHeader, PageBody } from '../../../components/page-components';
import {
  InvoiceStatusBadge,
  LocationTransactions,
  Details,
  SendInvoiceModal,
  MarkAsPaidModal,
  MasterSubTransactions,
  SendInvoiceXslModal
} from '.';
import { useDownloadInvoicePdf, useRegeneratedInvoiceSubscription, useMarkInvoiceAsPaid } from '../hooks';
import { GenerateInvoiceXslModal, InvoiceRegenerate, VoidInvoiceModal } from '../containers';
import { INVOICE_PAYMENT_STATUSES, INVOICE_STATUSES } from '../constants';

import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INVOICE_STATUS } from '.';
import { FeatureFlag } from '../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function InvoicePage({ invoice, viewingClientInvoice }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  useRegeneratedInvoiceSubscription(invoice, {
    onInvoiceRegenerated({ newInvoiceId }) {
      history.push(`/invoices/${newInvoiceId}`);
    }
  });

  const {
    roleIsAtLeast,
    TYPES: { CLIENT },
    ROLES: { POLICY_MANAGER, EXPOSURE_MANAGER, OBSERVER }
  } = useActiveAccount();
  const [isClientInvoice, setisClientInvoice] = useState(viewingClientInvoice);
  const { generateInvoicePdf, error: pdfDownloadError } = useDownloadInvoicePdf({ id: invoice?.id });

  const [showVoid, toggleVoid] = useToggle();
  const [sendInvoiceOpen, toggleSendInvoice] = useToggle();
  const [showMarkAsPaid, toggleMarkAsPaid] = useToggle();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDownloadXsl, toggleDownloadXsl] = useToggle();
  const [sendInvoiceXslOpen, toggleSendInvoiceXsl] = useToggle();
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const invoicePaymentStatus = isClientInvoice ? invoice?.clientStatus : invoice?.brokerStatus;
  const total = isClientInvoice ? invoice?.clientTotal : invoice?.brokerTotal;

  const [markInvoiceAsPaid, { loading: markingAsPaid, error: markAsPaidError }] = useMarkInvoiceAsPaid({
    onCompleted: toggleMarkAsPaid,
    isClientInvoice
  });

  const policyStatus = invoice?.clientProduct?.effectiveStatus;
  const policyIsArchived = policyStatus === 'ARCHIVED';

  const isNegativeTotal = total < 0;

  const [groupByOption, setGroupByOption] = useState('LOCATION');

  const groupByOptions = [
    { label: 'Location', value: 'LOCATION' },
    { label: 'Master/Sub', value: 'MASTER_SUB' }
  ];

  return (
    <>
      <Helmet>
        <title>{`Invoice #${invoice?.id} | Invoices`}</title>
      </Helmet>
      <PageHeader withTabs={roleIsAtLeast(POLICY_MANAGER)}>
        <PageTitle>
          <span className={classNames({ 'text-muted': policyIsArchived })}>Invoice #{invoice?.id}</span>
          <Render if={policyIsArchived}>
            <Badge color="muted" className="ml-2">
              Archived
            </Badge>
          </Render>
          <Render if={!policyIsArchived}>
            <InvoiceStatusBadge invoice={invoice} className="ml-2" />
          </Render>
        </PageTitle>
      </PageHeader>

      <Switch>
        <Route exact path={`${path}`}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <TabBar className="px-5">
              <TabButton active={isClientInvoice} onClick={() => setisClientInvoice(true)}>
                Client View
              </TabButton>
              <HideFromTypes types={[CLIENT]}>
                <TabButton active={!isClientInvoice} onClick={() => setisClientInvoice(false)}>
                  Broker View
                </TabButton>
              </HideFromTypes>
            </TabBar>
          </HideFromRoles>
          <PageBody>
            <TabPage>
              <div className="d-flex justify-content-between pb-3">
                <span>
                  <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
                    <FiltersContainer className="">
                      <FilterLabel>Group by</FilterLabel>
                      <FilterDropdown
                        id="ordering"
                        className="mr-3"
                        headerText="Group transactions by..."
                        icon={['far', 'filter']}
                        options={groupByOptions}
                        defaultOption={groupByOptions.find(({ value }) => value === 'LOCATION')}
                        setFilter={option => setGroupByOption(option?.value)}
                        isClearable
                      />
                    </FiltersContainer>
                  </FeatureFlag>
                </span>
                <Dropdown isOpen={dropdownOpen} toggle={toggle} color="info" direction="down">
                  <DropdownToggle className="ml-2 btn btn-outline-info btn-sm">
                    <FontAwesomeIcon icon={['far', 'cog']} /> Actions
                  </DropdownToggle>
                  <DropdownMenu style={{ maxWidth: '215px' }} className="mr-4">
                    <DropdownItem onClick={toggleDownloadXsl}>
                      <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="pr-1" />
                      Download Invoice XSL
                    </DropdownItem>
                    <DropdownItem onClick={generateInvoicePdf}>
                      <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="pr-1" />
                      Download Invoice PDF
                    </DropdownItem>
                    <Render if={isClientInvoice}>
                      <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                        <DropdownItem onClick={toggleSendInvoiceXsl}>
                          <FontAwesomeIcon icon={['far', 'paper-plane']} className="pr-1" />
                          Send Invoice XSL
                        </DropdownItem>
                      </HideFromRoles>
                    </Render>
                    <Render if={isClientInvoice}>
                      <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                        <DropdownItem onClick={toggleSendInvoice}>
                          <FontAwesomeIcon icon={['far', 'paper-plane']} className="pr-1" />
                          Send Invoice PDF
                        </DropdownItem>
                      </HideFromRoles>
                    </Render>
                    <Render if={invoice?.status !== INVOICE_PAYMENT_STATUSES.PAID}>
                      <HideFromTypes types={[CLIENT]}>
                        <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                          <DropdownItem onClick={toggleMarkAsPaid}>
                            <FontAwesomeIcon icon={['far', 'usd-circle']} className="pr-1" />
                            Mark as Paid
                          </DropdownItem>
                        </HideFromRoles>
                      </HideFromTypes>
                    </Render>
                    <Render if={invoice?.status === INVOICE_STATUS.COMMITTED}>
                      <HideFromRoles roles={[EXPOSURE_MANAGER, OBSERVER]}>
                        <DropdownItem onClick={() => history.push(`${url}/regenerate`)}>
                          <FontAwesomeIcon icon={['far', 'sync-alt']} className="pr-1" />
                          Regenerate Invoice
                        </DropdownItem>
                      </HideFromRoles>
                    </Render>
                    <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                      <DropdownItem onClick={toggleVoid} disabled={invoice?.status === INVOICE_STATUS.VOIDED}>
                        <FontAwesomeIcon icon={['fas', 'eraser']} className="pr-1" />
                        Void Invoice
                      </DropdownItem>
                    </HideFromRoles>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <GraphQLErrors error={pdfDownloadError} />
              <BoxContainer className="p-3">
                <div className="d-flex justify-content-between pb-3 mb-3 border-bottom border-gray-300">
                  <span>
                    <InvoiceTopLabel label="Status">
                      <Render if={invoice?.status !== INVOICE_STATUSES?.COMMITTED}>
                        <InvoiceStatusBadge invoice={invoice} size="lg" />
                      </Render>
                      <Render if={invoice?.status === INVOICE_STATUSES?.COMMITTED}>
                        <Badge color={{ unpaid: 'muted', paid: 'green' }[invoicePaymentStatus]} size="lg">
                          {titleCase(invoicePaymentStatus)}
                        </Badge>
                      </Render>
                    </InvoiceTopLabel>
                  </span>
                  <span className="d-flex justify-content-end">
                    <InvoiceTopLabel
                      className={`ml-5 ${isNegativeTotal ? 'text-danger' : ''}`}
                      label="Total due"
                      loading={invoice?.status === INVOICE_STATUSES?.OPENED}
                      value={formatCurrency(total)}
                    />
                    <InvoiceTopLabel
                      className="ml-5"
                      label="Invoice date"
                      value={moment(invoice?.invoiceDate).format('M-DD-YYYY')}
                    />
                    <InvoiceTopLabel
                      className="ml-5"
                      label="Due on"
                      value={moment(invoice?.dueDate).format('MMMM Do, YYYY')}
                    />
                  </span>
                </div>
                <Details invoice={invoice} />
                {groupByOption === 'LOCATION' && (
                  <LocationTransactions isClientInvoice={isClientInvoice} invoice={invoice} />
                )}
                {groupByOption === 'MASTER_SUB' && (
                  <MasterSubTransactions isClientInvoice={isClientInvoice} invoice={invoice} />
                )}
              </BoxContainer>

              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                <SendInvoiceModal isOpen={sendInvoiceOpen} toggle={toggleSendInvoice} invoice={invoice} />
              </HideFromRoles>

              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                <MarkAsPaidModal
                  invoice={invoice}
                  isOpen={showMarkAsPaid}
                  toggle={toggleMarkAsPaid}
                  loading={markingAsPaid}
                  error={markAsPaidError}
                  onSubmit={() => markInvoiceAsPaid({ variables: { invoiceId: invoice?.id } })}
                />
              </HideFromRoles>

              <VoidInvoiceModal isOpen={showVoid} toggle={toggleVoid} invoice={invoice} />

              {showDownloadXsl && (
                <GenerateInvoiceXslModal
                  isOpen={showDownloadXsl}
                  toggle={toggleDownloadXsl}
                  invoice={invoice}
                  groupByOptions={groupByOptions}
                />
              )}

              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                {sendInvoiceXslOpen && (
                  <SendInvoiceXslModal
                    isOpen={sendInvoiceXslOpen}
                    toggle={toggleSendInvoiceXsl}
                    invoice={invoice}
                    groupByOptions={groupByOptions}
                  />
                )}
              </HideFromRoles>
            </TabPage>
          </PageBody>
        </Route>
        <Route path={`${path}/regenerate`}>
          <InvoiceRegenerate onCompleted={() => history.goBack()} invoice={invoice} />
        </Route>
      </Switch>
    </>
  );
}

const InvoiceTopLabel = ({ label, value, className, loading = false, children }) => (
  <span className={className}>
    <Label>{label}</Label>
    <Render if={loading}>
      <SkeletonLoader width="100%" height={23} shape="pill" className="mt-1" />
    </Render>
    <Render if={!!children && !loading}>{children}</Render>
    <Render if={!children && !loading}>
      <div className="fs-lg mt-1">{value}</div>
    </Render>
  </span>
);
