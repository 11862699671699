import React from 'react';
import { Table } from '../../../components/table-2.0';
import { TrPlaceholder, TrLoader } from '@oforce/global-components';

import { TablePagination } from '../../../components/tables';
import { useActiveAccount, HideFromTypes, ACCOUNT_TYPES } from '../../../shared/activeAccount';
import { BoxContainer, Render, RenderData, TrGraphQLErrors } from '../../../components';

import { PolicyInvoicesTableRow } from '.';

export default function PolicyInvoicesTable({ invoices, loading, error, paginationProps }) {
  const {
    roleIsAtLeast,
    ROLES: { POLICY_MANAGER }
  } = useActiveAccount();
  const isAtLeastPolicyManager = roleIsAtLeast(POLICY_MANAGER);
  const colSpan = isAtLeastPolicyManager ? 7 : 6;
  const { CLIENT } = ACCOUNT_TYPES;

  return (
    <>
      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Number</th>
              <th>Date</th>
              <th>Service Dates</th>
              <th>Due</th>
              <th>Client Total</th>
              <HideFromTypes types={[CLIENT]}>
                <Render if={isAtLeastPolicyManager}>
                  <th>Broker Total</th>
                </Render>
              </HideFromTypes>
              <th>Policy External Id</th>
              <th>Client Name</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={invoices}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No invoices</TrPlaceholder>}
            >
              {invoices =>
                invoices?.map(invoice => (
                  <PolicyInvoicesTableRow key={invoice.id} invoice={invoice} showBrokerTotal={isAtLeastPolicyManager} />
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
