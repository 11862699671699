import { Button, FieldError, useToggle } from '@oforce/global-components';
import React, { useState } from 'react';
import { RowSummary } from '.';
import { GraphQLErrors, SwitchInput, TabPage } from '..';
import { LargeIcon, Message, Title } from '../message-box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FactorValueImportSummary({
  rows,
  onBack,
  onCompleted,
  formOptions,
  policy,
  useValidation,
  submitAttrs,
  useRowValidation
}) {
  const [viewingInvalidOnly, toggleInvalidFilter] = useToggle(true);
  const [updatingRow, setRowUpdating] = useState(null);

  const {
    validatedRows,
    updateValidations,
    loading,
    invalidRowIds,
    error: validationErrors
  } = useValidation({
    rows,
    policy,
    formOptions,
    onCompleted
  });

  const toggleUpdating = row => {
    if (row && row?.rowId === updatingRow) return setRowUpdating(null);
    if (row && row?.rowId !== updatingRow) return setRowUpdating(row?.rowId);
    setRowUpdating(null);
  };

  if (validationErrors) return <ValidationErrorsMessage validationErrors={validationErrors} onClick={onBack} />;
  if (loading) return <LoadingMessaage />;

  const filteredRows = viewingInvalidOnly
    ? validatedRows.filter(({ rowId }) => invalidRowIds.includes(rowId))
    : validatedRows;

  return (
    <>
      {!loading && !!submitAttrs?.error && <GraphQLErrors error={submitAttrs?.error} />}

      <TabPage
        size="fw"
        title={
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span>Review Exposure Rows</span>
            <SwitchInput
              onChange={toggleInvalidFilter}
              isActive={viewingInvalidOnly}
              size="sm"
              activeLabel="Invalid Rows Only"
              inactiveLabel="Invalid Rows Only"
              name="fitlerInvalidOnlySwitch"
              className="justify-content-end ml-5 fs-sm font-weight-400 "
            />
          </div>
        }
      >
        <FieldError name="factorValues" />
        {filteredRows.length > 0 ? (
          filteredRows.map(row => (
            <RowSummary
              formOptions={formOptions}
              toggle={() => toggleUpdating(row)}
              isUpdating={updatingRow === row?.rowId}
              updateRow={updateValidations}
              key={row?.rowId}
              row={row}
              rows={rows}
              validatedRows={validatedRows}
              useRowValidation={useRowValidation}
            />
          ))
        ) : (
          <div className=" pt-5 d-flex flex-column align-items-center">
            <FontAwesomeIcon size="3x" className={`text-success `} icon={['far', 'check']} />
            <Title className="mb-3 mt-3 ">All Rows are Valid!</Title>
            <Message className="d-flex flex-column align-items-center">
              You can now submit your rows for processing.
              <br></br>
              Thanks for hanging out with us while you wait.
              <br></br>
            </Message>
          </div>
        )}
      </TabPage>
    </>
  );
}

const LoadingMessaage = () => {
  return (
    <div className=" pt-5 d-flex flex-column align-items-center mb-5">
      <LargeIcon icon={['far', 'spinner-third']} color="info" spin />
      <Title className="mb-3">Parsing your file...</Title>
      <Message>
        The larger the file, the longer the parsing process. <br></br>Thanks for hanging out with us while you wait.
      </Message>
    </div>
  );
};

const ValidationErrorsMessage = ({ validationErrors, onClick }) => {
  return (
    <div className=" pt-5 pb-5 d-flex flex-column align-items-center mb-5">
      <Title className="mb-3">Please fix the below errors in your csv import file...</Title>
      <GraphQLErrors error={validationErrors} />
      <Button color="info" onClick={onClick} className="d-inline-flex p-2">
        Return to File Select
      </Button>
    </div>
  );
};
