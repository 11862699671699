import { FieldLabel, RangePicker, useToggle } from '@oforce/global-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterDropdown, FilterLabel, SwitchInput } from '../../../components';

export default function InvoiceFilters({ setDateFilter, setVariable, variables }) {
  const [showVoided, toggleVoided] = useToggle(false);

  const setVoided = () => {
    setVariable('voided', !showVoided);
    toggleVoided();
  };

  const orderOptions = [
    { label: 'Invoice ID', value: 'INVOICE_ID' },
    { label: 'Client Name', value: 'CLIENT_NAME' },
    { label: 'External ID', value: 'EXTERNAL_ID' },
    { label: 'Service Date Range', value: 'SERVICE_START_DATE' },
    { label: 'Invoice Date', value: 'INVOICE_DATE' }
  ];

  return (
    <div className="mb-3">
      <div className="mb-3">
        <FieldLabel name="Filter By Date" />
        <RangePicker
          style={{ minHeight: '38px' }}
          format="MM/DD/YYYY"
          onChange={setDateFilter}
          separator={<FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} className="text-gray-500 ml-0" />}
        />
      </div>
      <div className="d-flex align-items-center">
        <FilterLabel>Sort by</FilterLabel>
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions[0]}
          setFilter={option => {
            setVariable(Object.assign(variables, { ordering: option.value }));
          }}
        />

        <span className="font-weight-500 mr-2">Filters</span>
        <SwitchInput
          className="ml-3"
          isActive={showVoided}
          size="sm"
          activeLabel="Voided"
          inactiveLabel="Voided"
          onChange={setVoided}
        />
      </div>
    </div>
  );
}
