import React from 'react';
import { useParams, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Loader } from '@oforce/global-components';

import { PageHeader, PageTitle } from '../../components/page-components';

import { TabBar, TabLink, TabPane } from '../../components/tabs';
import { GraphQLErrors } from '../../components';

import { ProductDetails, ProductPricing, ProductDocuments, ProductConfiguration } from '../../views';
import { useProduct } from './hooks';
import { ProductInvoiceTemplateWorkflow } from '../productDetails/containers';

export { PRODUCT_CORE_FIELDS, GET_PRODUCT } from './hooks/useProduct';

export default function Product() {
  const { url, path } = useRouteMatch();
  const { productId } = useParams();

  const { product, loading, error } = useProduct({ id: productId });

  if (loading && !product) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <>
      <PageHeader withTabs>
        <PageTitle>{product?.name}</PageTitle>

        <TabBar>
          <TabLink to={`${url}/details`} icon={['far', 'info-square']}>
            Details
          </TabLink>
          <TabLink to={`${url}/configuration`} icon={['far', 'cog']}>
            Configuration
          </TabLink>
          <TabLink to={`${url}/pricing`} icon={['far', 'box-usd']}>
            Pricing
          </TabLink>
          <TabLink to={`${url}/documents`} icon={['far', 'file']}>
            Documents
          </TabLink>
        </TabBar>
      </PageHeader>

      <TabPane>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/details`} />
          <Route path={`${path}/details`}>
            <ProductDetails product={product} />
          </Route>
          <Route path={`${path}/configuration`}>
            <ProductConfiguration product={product} />
          </Route>
          <Route path={`${path}/pricing`}>
            <ProductPricing product={product} />
          </Route>
          <Route path={`${path}/documents`}>
            <ProductDocuments product={product} />
          </Route>
          <Route path={`${path}/sync-invoice-template`}>
            <ProductInvoiceTemplateWorkflow product={product} />
          </Route>
        </Switch>
      </TabPane>
    </>
  );
}
