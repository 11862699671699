import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import useUploadProductInvoiceTemplate from '../hooks/useUploadProductInvoiceTemplate';
import InvoiceTemplateForm from '../../invoiceTemplate/components/InvoiceTemplateForm';

export default function UploadProductInvoiceTemplate({ isOpen, toggle, productId, hasInvoiceTemplate }) {
  const { upload, loading, error, initialValues, validationSchema } = useUploadProductInvoiceTemplate({
    onCompleted: toggle,
    productId
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={upload}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title={`${hasInvoiceTemplate ? 'Replace' : 'Upload'} Invoice Template`}
          loading={loading}
          onSubmit={formik.handleSubmit}
          submitButtonText="Upload Template"
        >
          {error && <GraphQLErrors error={error} />}
          <InvoiceTemplateForm loading={loading} />
        </Modal>
      )}
    </Formik>
  );
}
