import _ from 'lodash';
import { useState } from 'react';
import { gql } from '@apollo/client';
import { debounce } from 'throttle-debounce';

import { usePaginatedQuery } from '../../../hooks';

export const GET_POLICY_INVOICES = gql`
  query invoices(
    $start: Date
    $end: Date
    $clientId: ID
    $productId: ID
    $policyId: ID
    $first: Int
    $before: String
    $last: Int
    $after: String
    $archived: Boolean
    $voided: Boolean
    $ordering: ListInvoicesOrdering
  ) {
    invoices(
      first: $first
      before: $before
      last: $last
      after: $after
      clientId: $clientId
      productId: $productId
      policyId: $policyId
      invoiceStartDate: $start
      invoiceEndDate: $end
      archived: $archived
      voided: $voided
      ordering: $ordering
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          type
          policyId
          dueDate
          serviceStartDate
          serviceEndDate
          invoiceDate
          clientTotal
          brokerTotal
          clientStatus
          brokerStatus
          status
          clientProduct {
            id
            effectiveStatus
            externalId
            name
            insuranceType {
              name
            }
          }
          client {
            id
            name
          }
        }
      }
    }
  }
`;

export default function usePolicyInvoices({ policyId, policy }) {
  const defaultFilters = {
    first: 20,
    start: null,
    end: null,
    clientId: null,
    productId: null,
    policyId,
    archived: policy?.archived || false,
    voided: false,
    ordering: 'INVOICE_ID'
  };

  const [variables, setVariables] = useState(defaultFilters);

  const filtersAreDefault = _.isEqual(variables, defaultFilters);

  const { data, loading, error, refetch, paginationProps, loadFirstPage, queryProps } = usePaginatedQuery(
    GET_POLICY_INVOICES,
    {
      variables,
      pathToPageInfo: 'invoices',
      pageSize: 20
    }
  );

  const setVariable = debounce(500, false, (key, value) => {
    let data = null;
    if (value || value === false) data = value;
    return setVariables(x => {
      const props = { ...x, [key]: data };
      loadFirstPage(props);
      return props;
    });
  });

  const setDateFilter = dates => {
    if (dates) {
      const [startDate, endDate] = dates;
      let start = null;
      let end = null;

      if (startDate) start = startDate.format('YYYY-MM-DD');
      if (endDate) end = endDate.format('YYYY-MM-DD');

      return setVariables(x => {
        const props = { ...x, start, end };
        loadFirstPage(props);
        return props;
      });
    }

    return setVariables(x => {
      const props = { ...x, start: null, end: null };
      loadFirstPage(props);
      return props;
    });
  };

  const clearFilters = () =>
    setVariables(x => {
      const props = { ...x, ...defaultFilters };
      loadFirstPage(props);
      return props;
    });

  return {
    invoices: data?.invoices?.edges?.map(x => x.node),
    loading,
    error,
    refetch,
    variables,
    setVariable,
    setDateFilter,
    filtersAreDefault,
    clearFilters,
    paginationProps,
    queryProps
  };
}
