import { FieldMessage, InputDropzoneField } from '@oforce/global-components';
import { Formik } from 'formik';
import React from 'react';
import { Modal } from '@oforce/global-components';
import ModalFileTemplateLink from './ModalFileTemplateLink';
import { useCsvImport } from '../../../policyInsureds/components/import/hooks';
import { useParseFactorValuesCsvFile } from './hooks';
import { Bold } from '../../../../components';

export default function UploadFactorValuesImportFileModal({
  onParseComplete,
  isOpen,
  toggle,
  policy,
  startDate,
  endDate
}) {
  const { parseData, loading } = useParseFactorValuesCsvFile({
    policy,
    onComplete: onParseComplete
  });

  const { initialValues, validationSchema } = useCsvImport();

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={parseData}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Upload Factor Values CSV"
          submitButtonText="Upload CSV"
          onSubmit={formik.handleSubmit}
          loading={loading}
          submitButtonProps={{ loadingText: 'Parsing File...', disabled: !formik.values.document }}
        >
          <>
            <div className="mb-3">Use the steps below to provide us the needed factor values.</div>
            <ol>
              <li className="mb-2">
                <ModalFileTemplateLink policy={policy} startDate={startDate} endDate={endDate} />
              </li>
              <li className="mb-2">
                <Bold> Remove </Bold> any coverages (rows) you do not want to be on the invoice.
              </li>
              <li className="mb-2">
                Fill in any rows with an empty <Bold> factor_value </Bold> column.
                <FieldMessage className="fs-sm" icon={['far', 'info-circle']}>
                  Rows with N/A in that column do not need a value.
                </FieldMessage>
              </li>
              <li className="mb-2">
                <Bold>Upload </Bold> your finished file and we will do the rest.
              </li>
            </ol>
            <InputDropzoneField
              className="mb-2 mt-4"
              required
              accept={['.csv']}
              id="document"
              label="Upload Filled Out Template"
              name="document"
              disabled={loading}
              readOnly={loading}
              placeholder={'Choose CSV file'}
            />
          </>
        </Modal>
      )}
    </Formik>
  );
}
