import * as yup from 'yup';
import { uuid } from '../../../utils/regex';

export default function useRegenerateInvoiceFormSteps({ invoice, policy }) {
  const valuesStep = {
    name: 'Import Factor Values',
    initialValues: { factorValues: null },
    validationSchema: yup.object({
      factorValues: yup
        .object({
          allValid: yup.boolean().nullable(),
          validatedRows: yup
            .array()
            .of(yup.object({ exposureElectionId: yup.string().nullable(), factorValue: yup.string().nullable() }))
            .when('$factorValues', (_value, schema) =>
              !!policy?.hasApiFactoredField ? schema.required('Required') : schema.nullable()
            )
        })
        .when('$factorValues', (_value, schema) =>
          !!policy?.hasApiFactoredField ? schema.required('Required') : schema.nullable()
        )
        .test({
          name: uuid(),
          message: `All Factor Value rows must be valid`,
          test: value => !!value.allValid
        })
    })
  };
  const theStep = {
    name: 'Reenerate Invoice',
    initialValues: {
      invoiceId: invoice?.id,
      notes: ''
    },
    validationSchema: yup.object({
      notes: yup.string().required('Required').max(255, 'Must be 255 characters or less')
    })
  };

  const formSteps = policy?.hasApiFactoredField ? [valuesStep, theStep] : [theStep];

  return { formSteps };
}
