import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, useToggle } from '@oforce/global-components';

import { useDownloadFile } from '../../../hooks';
import { BoxContainer, BoxContainerTitle, BoxItem, Render } from '../../../components';
import { useCurrentUser } from '../../../shared/currentUser';
import UploadProductInvoiceTemplate from './UploadProductInvoiceTemplate';
import { MappableFields } from '../../invoiceTemplate/components';

export default function ProductInvoiceTemplate({ invoiceTemplate, productId, product }) {
  const history = useHistory();
  const { isAdmin } = useCurrentUser();

  const [showUploadTemplate, toggleUploadTemplate] = useToggle();
  const [showMappableFields, toggleMappableFields] = useToggle();

  const { download, loading } = useDownloadFile({
    url: invoiceTemplate?.url,
    filename: invoiceTemplate?.filename
  });

  if (!isAdmin) return null;

  const shouldShowDocumentButtons = product => {
    if (product?.archived) return false;
    else if (product?.expirationDate !== null && new Date(product?.expirationDate) <= new Date()) return false;
    else return true;
  };

  return (
    <>
      <BoxContainerTitle>
        <div className="d-flex align-items-center justify-content-between">
          Invoice Template
          <div className="d-flex align-items-center">
            <Render if={shouldShowDocumentButtons(product)}>
              <Button color="link" icon={['far', 'file-upload']} className="p-0 pr-2" onClick={toggleUploadTemplate}>
                {invoiceTemplate ? 'Replace' : 'Upload'}
              </Button>
              <Render if={!!invoiceTemplate}>
                <Button
                  color="link"
                  icon={['far', 'sync-alt']}
                  className="p-0"
                  onClick={() => history.push(`/products/${productId}/sync-invoice-template`)}
                >
                  Sync to policies
                </Button>
              </Render>
            </Render>
          </div>
        </div>
      </BoxContainerTitle>
      <BoxContainer className="py-1">
        <Render if={!!invoiceTemplate}>
          <BoxItem>
            <Button
              color="link"
              icon={['far', 'arrow-to-bottom']}
              onClick={download}
              loading={loading}
              loadingText="Downloading..."
            >
              {invoiceTemplate?.filename}
            </Button>
          </BoxItem>
        </Render>
        <Render if={!invoiceTemplate}>
          <BoxItem>None</BoxItem>
        </Render>
      </BoxContainer>

      <Button color="link" icon={['far', 'info-circle']} className="p-0 fs-xs mt-2" onClick={toggleMappableFields}>
        View Mappable Fields
      </Button>

      <UploadProductInvoiceTemplate
        isOpen={showUploadTemplate}
        toggle={toggleUploadTemplate}
        productId={productId}
        hasInvoiceTemplate={invoiceTemplate}
      />

      <MappableFields open={showMappableFields} toggle={toggleMappableFields} />
    </>
  );
}
