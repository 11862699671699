import { gql, useMutation } from '@apollo/client';
import * as yup from 'yup';

export const UPLOAD_PRODUCT_INVOICE_TEMPLATE = gql`
  mutation UploadProductInvoiceTemplate($productId: ID!, $fileData: Upload!) {
    uploadProductInvoiceTemplate(fileData: $fileData, productId: $productId) {
      id
      invoiceTemplate {
        filename
        url
      }
    }
  }
`;

export default function useUploadProductInvoiceTemplate({ onCompleted = () => {}, onError = () => {}, productId }) {
  const [update, { loading, error }] = useMutation(UPLOAD_PRODUCT_INVOICE_TEMPLATE, { onCompleted, onError });
  const initialValues = { document: null, name: '' };
  const DOCUMENT_VALIDATION = { SUPPORTED_FORMATS: ['application/pdf'], MAX_FILE_SIZE: 50000000 };
  const validationSchema = yup.object({
    document: yup.mixed().when('$document', (_value, schema) =>
      schema
        .required('Required')
        .test('fileType', 'File must be a PDF', values =>
          values
            ?.map(value => DOCUMENT_VALIDATION.SUPPORTED_FORMATS.includes(value?.type))
            .reduce((acc, valid) => acc && valid, true)
        )
        .test('fileSize', 'File must be less than 50 MB', values =>
          values
            ?.map(value => value && value.size < DOCUMENT_VALIDATION.MAX_FILE_SIZE)
            .reduce((acc, valid) => acc && valid, true)
        )
    )
  });
  return {
    upload: ({ document }) => update({ variables: { productId, fileData: document[0] } }),
    loading,
    error,
    initialValues,
    validationSchema
  };
}
