import { Button, useToggle } from '@oforce/global-components';
import React, { useState } from 'react';
import UploadFactorValuesImportFileModal from './UploadFactorValuesImportFileModal';
import { useValidation, useRowValidation } from './hooks';
import { FactorValueImportSummary } from '../../../../components/csvValidation';

export default function ImportFactorValues({ policy, onCompleted, startDate, endDate }) {
  const [reviewingRows, setReviewingRows] = useState(false);
  const [uploadModalIsOpen, toggleUploadModal] = useToggle(false);
  const [parsedRows, setParsedRows] = useState([]);

  return (
    <>
      {!reviewingRows && (
        <div className="p-4 p-md-5 mb-3">
          <h3 style={{ letterSpacing: '-1px' }}>Import Factor Values to Generate Invoice</h3>
          <div className="mt-3 mb-5">
            <span>This policy contains pricing options that are factored on external values. </span>
            <br></br>
            <span>Upload a file containing those values and we will generate the invoice.</span>
          </div>

          <div className="d-flex">
            <Button color="success" onClick={toggleUploadModal}>
              Choose Csv File
            </Button>
          </div>
        </div>
      )}

      {reviewingRows && (
        <FactorValueImportSummary
          policy={policy}
          useValidation={useValidation}
          useRowValidation={useRowValidation}
          rows={parsedRows}
          onCompleted={onCompleted}
          onBack={() => setReviewingRows(false)}
        />
      )}

      {uploadModalIsOpen && (
        <UploadFactorValuesImportFileModal
          policy={policy}
          startDate={startDate}
          endDate={endDate}
          isOpen={uploadModalIsOpen}
          toggle={toggleUploadModal}
          onParseComplete={data => {
            setParsedRows(data);
            setReviewingRows(true);
            toggleUploadModal();
          }}
        />
      )}
    </>
  );
}
