import React from 'react';
import { useHistory } from 'react-router-dom';

import { MultiStepForm } from '../../../components';
import { Steps } from 'react-step-builder';
import SelectPoliciesFormStep from '../components/SelectPoliciesFormStep';
import useSynchronizeProductInvoiceSteps from '../hooks/useSynchronizeProductInvoiceSteps';
import ReviewPoliciesStep from '../components/ReviewPoliciesFormStep';
import useSyncProductInvoiceToPolicies from '../hooks/useSyncProductInvoiceToPolicies';

export default function ProductInvoiceTemplateWorkflow({ product }) {
  const history = useHistory();

  const formSteps = useSynchronizeProductInvoiceSteps({ productId: product?.id });

  const onCompleted = () => history.push(`/products/${product?.id}`);
  const { error, loading, handleSubmit } = useSyncProductInvoiceToPolicies({ onCompleted });

  const formSubmit = (props) => handleSubmit(props)


  return (
    <MultiStepForm
      title="Synchronize product invoice template"
      formSteps={formSteps}
      formSubmit={formSubmit}
      onCancel={() => history.goBack()}
      submitButtonText="Synchronize"
      submitButtonProps={{ loading, loadingText: 'Synchronizing...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <>
          <Steps>
            <SelectPoliciesFormStep product={product} {...formProps} />
            <ReviewPoliciesStep {...formProps} />
          </Steps>
        </>
      )}
    </MultiStepForm>
  );
}
