import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const GET_POLICY_FEES = gql`
  query policyFees($id: ID!) {
    policy(id: $id) {
      id
      fees {
        id
        name
        rateMode
        rateModeType
      }
    }
  }
`;

export default function usePolicyFeeOptions({ policyId, fee, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_POLICY_FEES, {
    variables: { id: policyId },
    skip: !policyId,
    onCompleted,
    onError
  });

  const selectOptions = data?.policy?.fees
    ?.filter(fee => fee.rateModeType !== 'PREMIUM_PLUS')
    ?.filter(fee => fee.rateMode === 'FLAT')
    ?.filter(x => !fee || fee.id !== x.id)
    .map(fee => ({
      label: fee?.name,
      value: fee?.id
    }));

  return {
    policyFees: data?.policy?.fees,
    selectOptions,
    loading,
    error
  };
}
