import { useToggle } from '@oforce/global-components';
import React from 'react';
import { Tooltip } from 'reactstrap';

export default function FieldTooltip({ field }) {
  const { key, tooltip, example } = field;
  const [show, toggle] = useToggle(false);

  if (tooltip)
    return (
      <Tooltip key={`badge-${key}`} target={`badge-${key}`} placement="top" isOpen={show} toggle={toggle}>
        <p>{tooltip}</p>
        <p>{example}</p>
      </Tooltip>
    );
  else return null;
}
