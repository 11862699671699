import { gql, useMutation } from '@apollo/client';

const SYNC_PRODUCT_INVOICE_TO_POLICIES = gql`
  mutation UpdatePolicy($productId: ID!, $policyIds: [ID]) {
    syncProductInvoiceTemplatesToPolicies(productId: $productId, policyIds: $policyIds) {
      id
    }
  }
`;

export default function useSyncProductInvoiceToPolicies({ onCompleted, onError = () => {} }) {
  const [update, { loading, error }] = useMutation(SYNC_PRODUCT_INVOICE_TO_POLICIES, { onCompleted, onError });

  return {
    handleSubmit: ({ productId, policyIds }) => update({ variables: { productId, policyIds } }),
    loading,
    error
  };
}
