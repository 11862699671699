import { toHeaderCase } from 'js-convert-case';
import ReactMaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { DatePicker, Input, Label, ReactSelect } from '@oforce/global-components';
import { FeatureFlag } from '../../shared/activeAccount/components';
import { Alert } from 'reactstrap';
import moment from 'moment';
import { SERVER_SIDE_DATE_FORMAT } from '../../utils/dates';
import { FLAGGED_FEATURES } from '../../shared/activeAccount/constants';
import { addStakeholderPrefix } from '../../views/stakeholder/utils';
import { normalizeNumber } from '../../utils/formatting';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function DynamicInput({ field, ...props }) {
  if (TYPE_MAP[field]) return TYPE_MAP[field]({ field, ...props });
  else {
    return <TextInput type="text" field={field} {...getProps(props)} />;
  }
}

function selectState({ formOptions, field, onChange, className, row, ...props }) {
  const options = formOptions?.state?.options;

  return (
    <div className={className}>
      <Label>{toHeaderCase(field)}</Label>
      <ReactSelect
        isLoading={formOptions?.state?.loading}
        placeholder={`Select a state...`}
        isClearable
        onChange={option => onChange({ [field]: option?.value })}
        value={options?.find(({ value }) => value === row[field])}
        options={options}
        {...getProps(props)}
      />
    </div>
  );
}

const dateInput = ({ field, onChange, className, row }) => {
  const value = row[field] ? row[field] : '';

  return (
    <div className={className} style={{ width: '100%' }}>
      <Label>{toHeaderCase(field)}</Label>
      <DatePicker
        defaultValue={value ? moment(value) : null}
        format="MM/DD/YYYY"
        allowClear={false}
        onChange={e => {
          onChange({ [field]: moment(e).format(SERVER_SIDE_DATE_FORMAT) });
        }}
      />
      <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
        {field === 'startDate' && row?.contractType !== 'DIRECT' && (
          <Alert color="warning" className="mt-2">
            Note that the subs start date must be on or after master's start date. Also verify master's start date is
            valid before updating a subs start date.
          </Alert>
        )}
        {field === 'terminationDate' && row?.contractType !== 'DIRECT' && (
          <Alert color="warning" className="mt-2">
            The subs termination date must be after the master's start date, and before their termination date.
          </Alert>
        )}
      </FeatureFlag>
    </div>
  );
};

const getValue = (value, type) => {
  if (type === 'number') return number(value);
  return !!value ? value : '';
};

const number = string => {
  const parsed = !!string && parseInt(string);
  return !!parsed ? parsed : '';
};

function TextInput({ row, field, onChange, type, className, ...props }) {
  const value = getValue(row[field], type);

  return (
    <div className={className}>
      <Label>{toHeaderCase(field)}</Label>
      <Input
        placeholder={`Enter value`}
        value={value}
        onChange={e => onChange({ [field]: e?.target?.value })}
        type="text"
        {...getProps(props)}
      />
      {field === 'vehicleYear' && (
        <Alert color="warning" className="mt-2">
          Note that vehicle year must be in the format "YYYY".
        </Alert>
      )}
      {field === 'vin' && (
        <Alert color="warning" className="mt-2">
          Note that vin must be 17 characters.
        </Alert>
      )}
    </div>
  );
}

function DecimalInput({ row, field, onChange, type, className, ...props }) {
  const mask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 6,
    allowNegative: false,
    allowLeadingZeroes: true
  });

  return (
    <div className={className}>
      <Label>{toHeaderCase(field)}</Label>
      <ReactMaskedInput
        mask={mask}
        className="form-control"
        onChange={({ target }) => {
          const value = normalizeNumber(target.value);
          onChange({ [field]: value });
        }}
        {...getProps(props)}
      />
    </div>
  );
}

function stakeHolderFields(type) {
  return {
    [addStakeholderPrefix('name', type)]: props => <TextInput type="text" {...getProps(props)} />,
    [addStakeholderPrefix('address1', type)]: props => <TextInput type="text" {...getProps(props)} />,
    [addStakeholderPrefix('address2', type)]: props => <TextInput type="text" {...getProps(props)} />,
    [addStakeholderPrefix('city', type)]: props => <TextInput type="text" {...getProps(props)} />,
    [addStakeholderPrefix('email', type)]: props => <TextInput type="email" {...getProps(props)} />,
    [addStakeholderPrefix('phone', type)]: props => <TextInput type="tel" {...getProps(props)} />,
    [addStakeholderPrefix('state', type)]: props => selectState(props),
    [addStakeholderPrefix('zip', type)]: props => <TextInput type="text" {...getProps(props)} />,
    [addStakeholderPrefix('relationship', type)]: props => <TextInput type="text" {...getProps(props)} />
  };
}

const TYPE_MAP = {
  address: props => <TextInput type="text" {...getProps(props)} />,
  city: props => <TextInput type="text" {...getProps(props)} />,
  businessName: props => <TextInput type="text" {...getProps(props)} />,
  businessName2: props => <TextInput type="text" {...getProps(props)} />,
  country: ({ field, onChange, row, className, ...props }) => {
    const options = props.formOptions?.country?.options;
    const value = options?.find(({ value }) => value === row[field]);

    return (
      <div className={className}>
        <ReactSelect
          isLoading={props.formOptions?.country?.loading}
          placeholder={`Select a country...`}
          isClearable
          onChange={option => onChange({ [field]: option?.value })}
          value={value}
          options={options}
          {...getProps(props)}
        />
      </div>
    );
  },
  dateOfBirth: props => dateInput(props),
  startDate: props => dateInput(props),
  terminationDate: props => dateInput(props),
  dispatchLocation: props => <TextInput type="text" {...getProps(props)} />,
  email: props => <TextInput type="email" {...getProps(props)} />,
  middleName: props => <TextInput type="text" {...getProps(props)} />,
  firstName: props => <TextInput type="text" {...getProps(props)} />,
  lastName: props => <TextInput type="text" {...getProps(props)} />,
  mobilePhone: props => <TextInput type="tel" {...getProps(props)} />,
  ssn: props => <TextInput type="text" {...getProps(props)} />,
  state: props => selectState(props),
  zip: props => <TextInput type="text" {...getProps(props)} />,
  driversLicenseState: props => selectState(props),
  driversLicenseNumber: props => <TextInput type="text" {...getProps(props)} />,
  vehicleMake: props => <TextInput type="text" {...getProps(props)} />,
  vehicleYear: props => <TextInput type="number" {...getProps(props)} />,
  vehicleModel: props => <TextInput type="text" {...getProps(props)} />,
  vehicleWeight: props => <TextInput type="number" {...getProps(props)} />,
  vehicleType: ({ field, onChange, row, formOptions, className, ...props }) => {
    const options = formOptions?.vehicleTypes?.options;

    return (
      <div className={className}>
        <Label>{toHeaderCase(field)}</Label>
        <ReactSelect
          isLoading={props.formOptions?.vehicleTypes?.loading}
          placeholder={`Select a vehicle type...`}
          isClearable
          onChange={option => onChange({ [field]: option?.label })}
          value={options?.find(({ value }) => value === row[field])}
          options={options}
          {...getProps(props)}
        />
      </div>
    );
  },
  value: props => <DecimalInput {...getProps(props)} />,
  factorValue: props => <DecimalInput {...getProps(props)} />,
  mileage: props => <TextInput type="text" {...getProps(props)} />,
  weightClass: ({ field, onChange, row, className, ...props }) => {
    const options = props.formOptions?.weightClass?.options;
    return (
      <div className={className}>
        <Label>{toHeaderCase(field)}</Label>
        <ReactSelect
          isLoading={props.formOptions?.weightClass?.loading}
          placeholder={`Select a weight class...`}
          isClearable
          onChange={option => onChange({ [field]: option?.label })}
          value={options?.find(({ value }) => value === row[field])}
          options={options}
          {...getProps(props)}
        />
      </div>
    );
  },
  stateOfRegistration: props => selectState(props),
  commercialAutoCoverageType: ({ field, onChange, className, row, ...props }) => {
    const options = props.formOptions?.coverageType?.options;
    return (
      <div className={className}>
        <Label>{toHeaderCase(field)}</Label>

        <ReactSelect
          isLoading={props.formOptions?.coverageType?.loading}
          placeholder={`Select a coverage type...`}
          isClearable
          onChange={option => onChange({ [field]: option?.label })}
          value={options?.find(({ value }) => value === row[field])}
          options={options}
          {...getProps(props)}
        />
      </div>
    );
  },
  unitNumber: props => <TextInput type="text" {...getProps(props)} />,
  fleetNumber: props => <TextInput type="text" {...getProps(props)} />,
  serialNumber: props => <TextInput type="text" {...getProps(props)} />,
  isTractor: ({ field, onChange, row, className, ...props }) => {
    const options = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];

    return (
      <div className={className}>
        <Label>{toHeaderCase(field)}</Label>

        <ReactSelect
          placeholder={`Is it a tractor?...`}
          isClearable
          onChange={option => onChange({ [field]: option.value })}
          value={options?.find(({ value }) => value === row[field])}
          options={options}
          {...getProps(props)}
        />
      </div>
    );
  },
  notes: props => <TextInput type="text" {...getProps(props)} />,
  name: props => <TextInput type="text" {...getProps(props)} />,
  phone: props => <TextInput type="tel" {...getProps(props)} />,
  garagedZip: props => <TextInput type="text" {...getProps(props)} />,
  garagedState: props => selectState(props),
  ...stakeHolderFields('lossPayee'),
  ...stakeHolderFields('certHolder'),
  ...stakeHolderFields('additionalInsured'),
  ...stakeHolderFields('lienholder'),
  ...stakeHolderFields('beneficiary')
};

const getProps = ({ formOptions, ...rest }) => rest;
