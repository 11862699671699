import * as yup from 'yup';

export default function useSynchronizeProductInvoiceSteps({ productId }) {
  return [
    {
      name: 'Synchronize invoice templates to these policies',
      initialValues: { policyIds: [], productId },
      validationSchema: yup.object().shape({
        policyIds: yup.array()
          .of(yup.string())
          .min(1, 'At least one policy is required')
          .required('Policies are required')
      })
    },
    {
      name: 'Confirm',
      initialValues: {}
    }
  ];
}
