import { gql, useQuery } from '@apollo/client';
import useInsuranceTypeAcronyms from './useInsuranceTypeAcronyms';

export const GET_MAPPABLE_EXPOSURE_DOCUMENT_FIELDS = gql`
  query mappableExposureDocumentFields($productId: ID, $policyId: ID, $clientId: ID) {
    getExposureMappableFields(productId: $productId, policyId: $policyId, clientId: $clientId) {
      tooltip
      key
      category
      example
    }
  }
`;

export default function useMappableExposureDocumentFields({ productId, policyId, clientId }) {
  const { loading, error, data } = useQuery(GET_MAPPABLE_EXPOSURE_DOCUMENT_FIELDS, {
    skip: (productId && policyId && clientId) || (!policyId && !productId && !clientId),
    variables: { productId, policyId, clientId }
  });

  const { insuranceTypeAcronyms } = useInsuranceTypeAcronyms();

  const initialFields = {
    clientFields: [],
    insuredFields: [],
    vehicleFields: [],
    policyFields: [],
    pricingFields: [],
    electionFields: [],
    insuranceTypeFields: [],
    otherFields: []
  };

  const fields = data?.getExposureMappableFields?.reduce((acc, field) => {
    if (field.category === 'CLIENT_FIELDS') return { ...acc, clientFields: [...acc.clientFields, field] };
    if (field.category === 'INSURED_FIELDS') return { ...acc, insuredFields: [...acc.insuredFields, field] };
    if (field.category === 'VEHICLE_FIELDS') return { ...acc, vehicleFields: [...acc.vehicleFields, field] };
    if (field.category === 'POLICY_FIELDS') return { ...acc, policyFields: [...acc.policyFields, field] };
    if (field.category === 'PRICING_FIELDS') return { ...acc, pricingFields: [...acc.pricingFields, field] };
    if (field.category === 'ELECTION_FIELDS') return { ...acc, electionFields: [...acc.electionFields, field] };
    if (insuranceTypeAcronyms?.reduce((acc, acronym) => acc || field?.key.startsWith(acronym), false))
      return { ...acc, insuranceTypeFields: [...acc.insuranceTypeFields, field] };

    return { ...acc, otherFields: [...acc.otherFields, field] };
  }, initialFields);

  return {
    loading,
    error,
    ...fields
  };
}
