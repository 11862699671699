import React, { useState } from 'react';
import moment from 'moment';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { TabPage } from '../../components/tabs';
import { BoxContainer } from '../../components';
import { useClientDocuments } from './hooks';
import { DocumentsTable, UploadDocument, EditDocument } from './components';
import { MappableDocumentFields } from '..';
import { BulkEmail } from '../policyDocuments/containers';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BulkDownloadDocuments } from '../bulkDownloadDocuments';

export default function ClientDocuments({ client }) {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const { documents, loading, error } = useClientDocuments({ clientId: client?.id });

  const todayFormatted = moment().format(moment.HTML5_FMT.DATE);
  const validDocumentsForBulkDownload = documents?.filter(document => document?.effectiveDate <= todayFormatted);
  const validDocumentIdsForBulkDownload = validDocumentsForBulkDownload?.map(document => document?.id);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Documents"
          buttons={
            <Dropdown isOpen={dropdownOpen} toggle={toggle} color="info" direction="down">
              <DropdownToggle className="ml-2 btn btn-outline-info btn-sm">
                <FontAwesomeIcon icon={['far', 'cog']} /> Actions
              </DropdownToggle>
              <DropdownMenu style={{ maxWidth: '215px' }} className="mr-4">
                <DropdownItem onClick={() => history.push(`${url}/upload`)}>
                  <FontAwesomeIcon icon={['far', 'file-upload']} className="mr-1" style={{ minWidth: '20px' }} />
                  Upload Document
                </DropdownItem>
                <DropdownItem onClick={() => history.push(`${url}/email`)}>
                  <FontAwesomeIcon icon={['fas', 'envelope']} className="mr-1" style={{ minWidth: '20px' }} />
                  Bulk Email
                </DropdownItem>
                <DropdownItem onClick={() => history.push(`${url}/bulk-download`)}>
                  <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="mr-1" style={{ minWidth: '20px' }} />
                  Bulk Download
                </DropdownItem>
                <DropdownItem onClick={() => history.push(`${url}/mappable-fields`)}>
                  <FontAwesomeIcon icon={['far', 'info-circle']} className="mr-1" style={{ minWidth: '20px' }} />
                  View Mappable Fields
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          }
        >
          <BoxContainer className="mb-3">
            <DocumentsTable documents={documents} loading={loading} error={error} />
          </BoxContainer>
        </TabPage>
      </Route>

      <Route path={`${path}/mappable-fields`}>
        <MappableDocumentFields clientId={client?.id} onBack={() => history.push(`${url}`)} />
      </Route>

      <Route path={`${path}/upload`}>
        <UploadDocument clientId={client?.id} />
      </Route>

      <Route path={`${path}/email`}>
        <BulkEmail clientId={client?.id} redirectUrl={`/clients/${client?.id}/documents`} />
      </Route>

      <Route path={`${path}/:clientDocumentId/update`}>
        <EditDocument clientId={client?.id} />
      </Route>

      <Route path={`${path}/bulk-download`}>
        <BulkDownloadDocuments
          clientId={client?.id}
          documentIds={validDocumentIdsForBulkDownload}
          documents={validDocumentsForBulkDownload}
          redirectUrl={`/clients/${client?.id}/documents`}
        />
      </Route>
    </Switch>
  );
}
