import { FormSection } from '../../../components';
import { usePolicies } from '../../policies/hooks';

function insertCommasAndAnd(strings) {
    if (!strings) return '';
    if (strings?.length === 0) return '';
    if (strings?.length === 1) return strings[0];
    if (strings?.length === 2) return strings.join(' and ');
    return strings?.slice(0, -1).join(', ') + ', and ' + strings[strings.length - 1];
  }

export default function ReviewPoliciesStep({ formik, productId }) {
  const policyIds = formik?.values?.policyIds;
  const { policies } = usePolicies({ pageSize: 200, initialFilters: { productId } });
  const policiesToSynchronize = policies?.filter(policy => policyIds.includes(policy?.id));
  const policyNames = policiesToSynchronize?.map(({ name }) => name);

  return (
    <FormSection title="Confirm Policies">
      <div className="pt-5 pb-5 pl-5">
        You've selected {insertCommasAndAnd(policyNames)} to synchronize your invoice template to. 
        This action will overwrite the existing invoice templates with the one selected on this product.
      </div>
    </FormSection>
  );
}
