import React from 'react';
import { Loader } from '@oforce/global-components';

import { BoxContainer, BoxItemTitle, CopyableBadge, Render, TabBar, TabLink, TabPage } from '../../components';

import { useMappableExposureDocumentFields } from './hooks';
import FieldTooltip from './components/FieldTooltip';
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom';

export default function MappableDocumentFields({ productId, policyId, clientId, onBack }) {
  const {
    clientFields,
    insuredFields,
    vehicleFields,
    policyFields,
    pricingFields,
    otherFields,
    electionFields,
    insuranceTypeFields,
    loading
  } = useMappableExposureDocumentFields({ productId, policyId, clientId });

  const { path, url } = useRouteMatch();

  return (
    <TabPage title="Mappable Fields" showBackButton onBack={onBack}>
      <Render if={loading}>
        <Loader />
      </Render>
      <Render if={!loading}>
        <TabBar flush>
          {clientFields?.length > 0 && (
            <TabLink to={`${url}/client-fields`} icon={['far', 'info-square']}>
              Client
            </TabLink>
          )}
          {insuredFields?.length > 0 && (
            <TabLink to={`${url}/insured-fields`} icon={['far', 'info-square']}>
              Insured
            </TabLink>
          )}
          {vehicleFields?.length > 0 && (
            <TabLink to={`${url}/vehicle-fields`} icon={['far', 'info-square']}>
              Vehicle
            </TabLink>
          )}
          {policyFields?.length > 0 && (
            <TabLink to={`${url}/policy-fields`} icon={['far', 'info-square']}>
              Policy
            </TabLink>
          )}
          {pricingFields?.length > 0 && (
            <TabLink to={`${url}/pricing-fields`} icon={['far', 'info-square']}>
              Pricing
            </TabLink>
          )}
          {electionFields?.length > 0 && (
            <TabLink to={`${url}/election-fields`} icon={['far', 'info-square']}>
              Election
            </TabLink>
          )}
          {insuranceTypeFields?.length > 0 && (
            <TabLink to={`${url}/insurance-type-fields`} icon={['far', 'info-square']}>
              Insurance Type
            </TabLink>
          )}
          {otherFields?.length > 0 && (
            <TabLink to={`${url}/other-fields`} icon={['far', 'info-square']}>
              Other
            </TabLink>
          )}
        </TabBar>
      </Render>

      <Switch>
        <Redirect exact from={`${path}`} to={`${path}/client-fields`} />
        <Route path={`${path}/client-fields`}>
          <FieldBox title="Client Fields" fields={clientFields} />
        </Route>
        <Route path={`${path}/insured-fields`}>
          <FieldBox title="Insured Fields" fields={insuredFields} />
        </Route>
        <Route path={`${path}/vehicle-fields`}>
          <FieldBox title="Vehicle Fields" fields={vehicleFields} />
        </Route>
        <Route path={`${path}/policy-fields`}>
          <FieldBox title="Policy Fields" fields={policyFields} />
        </Route>
        <Route path={`${path}/pricing-fields`}>
          <FieldBox title="Pricing Fields" fields={pricingFields} />
        </Route>
        <Route path={`${path}/election-fields`}>
          <FieldBox title="Election Fields" fields={electionFields} />
        </Route>
        <Route path={`${path}/insurance-type-fields`}>
          <FieldBox title="Insurance Type Fields" fields={insuranceTypeFields} />
        </Route>
        <Route path={`${path}/other-fields`}>
          <FieldBox title="Other Fields" fields={otherFields} />
        </Route>
      </Switch>
    </TabPage>
  );
}

const FieldBox = ({ title, fields }) => {
  return (
    <BoxContainer className="p-3 m-4">
      <BoxItemTitle
        title={title}
        subtitle={
          <div className="fs-s">
            <span>
              Use these fields in your PDF, when the document is downloaded you will see the related information mapped
              to your document.
            </span>
          </div>
        }
      />
      <Fields fields={fields} />
    </BoxContainer>
  );
};

const Fields = ({ fields }) => {
  return (
    <div className="mb-4">
      <div className="d-flex flex-wrap">
        {fields?.map(field => (
          <div key={field?.key}>
            <Field field={field} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Field = ({ field }) => {
  const { key } = field;
  return (
    <>
      <CopyableBadge id={`badge-${key}`} className="m-2 mr-2 cursor-pointer" color={'blue'} text={key} />
      <FieldTooltip field={field} />
    </>
  );
};
