import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';

import { GraphQLErrors, MultiStepForm } from '../../../components';

import { GenerateInvoiceForm } from '../components';
import {
  useGenerateInvoiceFormOptions,
  useGenerateInvoiceFormSteps,
  useGenerateInvoiceFormSubmit,
  usePolicyInvoiceInfo
} from '../hooks';
import { Loader } from '@oforce/global-components';
import { ImportFactorValues } from '../components/import';
import { ensureFloat } from '../../../utils/formatting';
import { getServiceDates } from '../hooks/useGenerateInvoiceFormSubmit';

export default function GenerateInvoice({ refetchInvoicesArgs, policy }) {
  const history = useHistory();
  const policyId = policy?.id;

  const { formOptions } = useGenerateInvoiceFormOptions({ policyId });

  const {
    formSteps,
    loading: loadingInvoiceData,
    error: invoiceDataError,
    automateInvoiceGeneration
  } = useGenerateInvoiceFormSteps({ policyId, formOptions, policy });
  const { canGenerateOffCycleInvoice } = usePolicyInvoiceInfo({ policy });

  const { formSubmit, loading, error } = useGenerateInvoiceFormSubmit({
    policy,
    refetchInvoicesArgs,
    onCompleted: () => history.push(`/policies/${policyId}/invoices`),
    policyId
  });

  const formatFactorValue = value => {
    if (value === 'N/A') return null;
    else return ensureFloat(value);
  };

  const addFactorValues = (values, formik) => {
    const { allValid, validatedRows } = values;
    const results = validatedRows?.map(({ exposureElectionId, factorValue }) => {
      return { exposureElectionId, factorValue: formatFactorValue(factorValue) };
    });

    formik.setFieldValue('factorValues', { allValid, validatedRows: results });
  };

  if (loadingInvoiceData) return <Loader />;
  if (invoiceDataError) return <GraphQLErrors error={invoiceDataError} />;

  const getSteps = formProps => {
    if (policy?.hasApiFactoredField) {
      return (
        <Steps>
          <GenerateInvoiceForm {...formProps} formOptions={formOptions} policy={policy} />
          <ImportFactorValues
            {...formProps}
            {...getServiceDates(formProps?.formik?.values)}
            policy={policy}
            onCompleted={values => {
              addFactorValues(values, formProps?.formik);
            }}
          />
        </Steps>
      );
    }

    return (
      <Steps>
        <GenerateInvoiceForm {...formProps} formOptions={formOptions} policy={policy} />
      </Steps>
    );
  };

  return (
    <>
      <MultiStepForm
        maxWidth="100%"
        formOptions={formOptions}
        formSteps={formSteps}
        formSubmit={formSubmit}
        onCancel={() => history.push(`/policies/${policyId}/invoices`)}
        showProgressBar={false}
        loading={loading}
        error={error}
        title="Generate Invoice"
        submitButtonProps={{
          color: 'info',
          loading,
          loadingText: 'Generating...',
          disabled: automateInvoiceGeneration && !canGenerateOffCycleInvoice
        }}
        submitButtonText={canGenerateOffCycleInvoice ? 'Generate Off Cycle Invoice' : 'Generate Invoice'}
      >
        {formProps => getSteps(formProps)}
      </MultiStepForm>
    </>
  );
}
