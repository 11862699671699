import React from 'react';
import { Button } from '@oforce/global-components';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import { TabPage } from '../../components/tabs';
import { MappableDocumentFields } from '../../views';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';

import { useProductDocuments } from './hooks';
import { UploadUpdate } from './containers';
import { DocumentsTable } from './components';

export { defaultDocumentName } from './utils';

export default function ProductDocuments({ product }) {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const productId = product?.id;

  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();
  const { documents, loading, error } = useProductDocuments({ productId });

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Documents"
          buttons={
            <ShowForTypes types={[CARRIER]}>
              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                <Button
                  color="info"
                  outline
                  icon={['far', 'info-circle']}
                  className="ml-2"
                  onClick={() => history.push(`${url}/mappable-fields`)}
                >
                  View Mappable Fields
                </Button>
                <Button
                  color="info"
                  icon={['far', 'file-upload']}
                  className="ml-2"
                  onClick={() => history.push(`${url}/upload`)}
                >
                  Upload Document
                </Button>
              </HideFromRoles>
            </ShowForTypes>
          }
        >
          <DocumentsTable productId={productId} documents={documents} loading={loading} error={error} />
        </TabPage>
      </Route>

      <Route path={`${path}/mappable-fields`}>
        <MappableDocumentFields productId={productId} onBack={() => history.push(`${url}`)} />
      </Route>

      <Route path={`${path}/upload`}>
        <UploadUpdate productId={productId} />
      </Route>

      <Route path={`${path}/:documentId/update`}>
        <UploadUpdate productId={product?.id} documents={documents} />
      </Route>
    </Switch>
  );
}
